<template lang="html">
  <div class="Form">
    <!-- to save the user card, we have to call /charge -->
    <form action="/charge" method="post" id="payment-form">
      <div class="form-row">
        <div class="w-72 m-auto border border-primary-300 rounded">
          <div class="p-5 bg-primary-100 text-base font-semibold border-b border-primary-300">
            Credit/Debit Card
          </div>

          <div class="p-3">
            <div id="card-element">
              <!-- a Stripe Element will be inserted here. -->
            </div>
            <!-- Used to display Element errors -->
          </div>

          <div class="p-3 flex justify-end">
            <Button type="submit" v-show="status==''">Add Card</Button>
            <Button v-show="status=='saving'" variant="disabled">Saving...</Button>
          </div>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import axios from 'axios'
import Button from '@/components/base/buttons/Button.vue'
import CONSTANTS from '@/constants/CONSTANTS'
// eslint-disable-next-line no-undef
var stripe = Stripe(CONSTANTS.STRIPE_PUBLISH_KEY)
var elements = stripe.elements()
export default {
  name: 'Form',
  data () {
    return {
      status: ''
    }
  },
  components: {
    Button
  },
  props: {
    customerId: {
      type: String,
      required: true
    }
  },
  mounted () {
    // Custom styling can be passed to options when creating an Element.
    var style = {
      base: {
        // Add your base input styles here. For example:
        fontSize: '13px',
        lineHeight: '24px'
      }
    }

    // Create an instance of the card Element
    try {
      var card = elements.create('card', { hidePostalCode: true, style: style })

      // Add an instance of the card Element into the `card-element` <div>
      card.mount('#card-element')
      var vm = this
      card.addEventListener('change', function (event) {
        if (event.error) {
          vm.$emit('sourceError', event.error.message)
        } else {
          vm.$emit('sourceError', '')
        }
      })

      // Create a token or display an error when the form is submitted.
      var form = document.getElementById('payment-form')
      form.addEventListener('submit', function (event) {
        event.preventDefault()
        vm.status = 'saving'
        stripe.createToken(card).then(function (result) {
          if (result.error) {
            console.log(result.error.message)
            vm.status = ''
            vm.$emit('sourceError', result.error.message)
          } else {
            console.log(result.token)
            vm.stripeTokenHandler(result.token)
          }
        })
      })
    } catch (error) {
      console.warn(error)
    }
  },
  methods: {
    stripeTokenHandler (token) {
      // backend api update the source
      var vm = this
      // const customer_id = 'cus_AliBaJCMTL9kn5'
      const customerId = vm.customerId
      console.log('customer_id:' + customerId)
      if (customerId) {
        axios({
          method: 'get',
          url: `${CONSTANTS.BASE_LINK}/update_source`,
          params: {
            id: customerId,
            source_token: token.id
          }
        })
          .then(function (response) {
            console.log(response)
            if (response.status === 200) {
              vm.$emit('sourceAdded', response.data.customer.sources.data)
            } else if (response.status === 201) {
              vm.$emit('sourceError', response.data.error.split(': ')[1])
            } else {
              vm.$emit('sourceError', 'Problem adding Credit/Debit card. Please try again')
            }
            vm.status = ''
          })
          .catch(function (error) {
            console.log(error)
            vm.$emit('sourceError', 'Problem adding Credit/Debit card. Please try again')
            vm.status = ''
          })
      }
    }
  }
}
</script>

<style lang="css">

</style>
